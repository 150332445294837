<template>
  <div>
    <static-fullscreen-card v-show="!idItemShow">
      <template v-slot:header></template>
      <template v-slot:title>
        <v-row class="ma-1">
          <a-btn-menu v-if="getAccess('task.comments', null, true)" size="lg" icon="far fa-caret-square-down" :items="panelMenuItems" @click="onClickMenu" />

          <v-btn-toggle v-model="sortType" mandatory borderless dense @change="onTableRefresh()">
            <v-btn value="id-asc">
              <span class="hidden-md-and-down">Старые</span>
              <v-icon right> mdi-sort-clock-ascending-outline </v-icon>
            </v-btn>
            <v-btn value="id-desc" class="mx-1">
              <span class="hidden-md-and-down">Новые</span>
              <v-icon right> mdi-sort-clock-descending-outline </v-icon>
            </v-btn>
            <v-btn value="comment" v-if="getAccess('task.comments', null, true)">
              <span class="hidden-md-and-down">Сообщения</span>
              <v-icon right> mdi-comment-eye-outline </v-icon>
            </v-btn>
          </v-btn-toggle>
          <v-btn-toggle v-model="taskViewMode" dense multiple borderless @change="onTableRefresh()">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn class="mx-1" v-bind="attrs" v-on="on">
                  <v-icon left class="mx-0"> {{ taskViewMode.includes(0) ? "mdi-checkbox-outline" : "mdi-checkbox-blank-outline" }} </v-icon>
                  <v-icon right> mdi-account </v-icon>
                </v-btn>
              </template>
              <span>Мои задачи</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on">
                  <v-icon left class="mx-0"> {{ taskViewMode.includes(1) ? "mdi-checkbox-outline" : "mdi-checkbox-blank-outline" }} </v-icon>
                  <v-icon right> mdi-eye </v-icon>
                </v-btn>
              </template>
              <span>Наблюдатель</span>
            </v-tooltip>
            <v-tooltip v-if="getAccess('task.viewAll')" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn class="mx-1" v-bind="attrs" v-on="on">
                  <v-icon left class="mx-0"> {{ taskViewMode.includes(2) ? "mdi-checkbox-outline" : "mdi-checkbox-blank-outline" }} </v-icon>
                  <v-icon right> mdi-account-multiple </v-icon>
                </v-btn>
              </template>
              <span>Все задачи</span>
            </v-tooltip>
          </v-btn-toggle>
        </v-row>
      </template>
      <template v-slot:actions>
        <div v-if="modeRedirect">
          <a-btn-add @click="tryRedirect()" :disabled="loading" icon="fas fa-people-arrows" title="" />
          <a-btn-add @click="modeRedirect = false" :disabled="loading" icon="fas fa-ban" title="" />
        </div>
        <div v-else>
          <v-btn color="primary" @click="showCronList = !showCronList">
            {{ showCronList ? "Задачи" : "Расписаниe" }}
          </v-btn>
          <v-btn v-if="showCronList" color="primary" @click="createNewCron()"> Создать расписание </v-btn>
          <a-btn-add v-if="!showCronList" :color="'primary'" :title="'Создать задачу'" size="lg" @click="createNew()" />

          <a-btn-refresh color="primary" @click="updateData()" size="lg" />
          <a-btn-add v-if="!showCronList" @click="modeRedirect = true" :disabled="loading" icon="fas fa-people-arrows" title="" />
        </div>
      </template>
      <portal to="v-main">
        <task-dialog v-if="showTaskDialog" v-model="showTaskDialog" :id="idEdit"></task-dialog>
      </portal>
      <a-table-f-api
        v-if="!showCronList"
        ref="table"
        :api="url"
        :model="model"
        :useQuery="false"
        :defaults="defaults"
        :selectedRows="selectedRows"
        @click="
          counter += 1;
          onDetectClick($event);
        "
        @change-filter="onChangeFilter($event)"
        @rowContextMenu="onRowContextMenu($event)"
      />
      <a-table-f-api
        v-if="showCronList"
        ref="tableCron"
        :api="'/mechti/cron_tasks'"
        :model="modelCron"
        :useQuery="false"
        :defaults="{
          sort: [
            // { key: 'unviewedscomments_total', order: 'DESC' },
            { key: 'id', order: 'DESC' },
          ],
          paramName: 'taskCron',
        }"
        :selectedRows="selectedRows"
        @click="
          counter += 1;
          onDetectClick($event);
        "
        @change-filter="onChangeFilter($event)"
      />
      <popup-menu v-model="popumMenuShow" :items="popupMenuList" :position="popupMenuPosition" />
    </static-fullscreen-card>
    <RedirectTask v-if="showRedirectDialog" v-model="showRedirectDialog" @redirect="onRedirect($event)" />
    <cron-dialog v-model="showCronDialog" v-if="showCronDialog" :id="idEdit"></cron-dialog>
    <ViewItem v-if="idItemShow" :idShow="idItemShow" :api="taskViewMode.includes(2) ? 'mechti/tasks-admin' : 'mechti/tasks'" @close="itemShowClose($event)" />
    <context-menu ref="rowContextMenu" :items="contextMenuItems" @clickData="onContextMenuClick($event)" />
  </div>
</template>

<script>
//import taskDialog from "./dialogs/taskDialog.vue";
//import cronDialog from "./dialogs/taskCronDialog.vue";
import { getAccess, doubleClickDetect, popupMenu } from "@/components/mixings";

export default {
  mixins: [getAccess, doubleClickDetect, popupMenu],
  components: {
    taskDialog: () => import("./dialogs/taskDialog"),
    cronDialog: () => import("./dialogs/taskCronDialog"),
    ViewItem: () => import("./views/tasksView"),
    RedirectTask: () => import("./dialogs/taskRedirectDialog"),
  },
  data() {
    return {
      taskViewMode: [0, 1],
      sortType: "comment",
      view: 1,
      idEdit: 0,

      loading: false,
      idItemShow: 0,
      dialog: false,
      redirectList: null,
      modeRedirect: false,
      showRedirectDialog: false,
      showTaskDialog: false,
      showCronList: false,
      showCronDialog: false,
      mCron: this.$store.getters["config/get"].models.cron,
      m: this.$store.getters["config/get"].models.tasks,
      statuses: this.$store.getters["config/get"].models.tasks.statuses,
      url: "/mechti/tasks",
      filterName: "task",
      isTaskSelf: true,
      isTaskWatch: false,
      contextData: null,
    };
  },
  created() {
    console.log("task create");
    const filters = this.$root.getSessionFilter(this.filterName);
    this.$root.title = "Задачи " + (filters ? "(отфильтрованы)" : "");
    this.defaults.sessionFilters = filters || null;
  },
  computed: {
    panelMenuItems() {
      return [
        {
          text: "Отметить все комментарии прочитанными",
          action: async () => {
            await this.$axios.post(this.url + "/set_viewed_comments");
            this.$store.dispatch("state/badges", "task_comments");
            this.updateData();
          },
        },
      ];
    },
    defaults() {
      let filters = {};
      let where = {};

      if (this.taskViewMode.includes(2)) {
        this.url = "/mechti/tasks-admin";
      } else {
        this.url = "/mechti/tasks";
        if (this.taskViewMode.includes(0) && this.taskViewMode.includes(1)) {
        } else if (this.taskViewMode.includes(0)) {
          //мои
          where = { OR: [{ user_id: this.$root.profile.id }, { createdby_id: this.$root.profile.id }] };
        } else if (this.taskViewMode.includes(1)) {
          //наблюдатель
          where = {
            AND: [{ user_id: { condition: "<>", value: `${this.$root.profile.id}` } }, { createdby_id: { condition: "<>", value: `${this.$root.profile.id}` } }],
          };
        }
      }
      let sort = [];
      if (this.sortType == "id-asc") sort.push({ key: "id", order: "ASC", default: true });
      if (this.sortType == "comment") sort.push({ key: "unviewedscomments_total", order: "DESC", default: true });
      if (this.sortType == "id-desc" || this.sortType == "comment") sort.push({ key: "id", order: "DESC", default: true });
      // if (this.isTaskSelf) filters;
      return {
        sort,
        filters,
        paramName: "task",
        where,
      };
    },
    contextMenuItems() {
      let res = [
        { name: "viewThis", title: `Отметить эту задачу просмотренной`, disabled: !!this.contextData?.row?.viewedon },
        { name: "viewAll", title: `Отметить ВСЕ задачи просмотренными` },
        { name: "viewComments", title: `Отметить комментарии прочитанными`, disabled: !this.contextData?.row?.unviewedscomments_total },
      ];
      return res;
    },
    statusList() {
      let list = [];
      //return this.statuses;
      let idx;
      let type = {
        new: "*Новые",
        progress: "*В процессе",
        ready: "*Завершенные",
        cancel: "*Отмененные",
      };
      let text;
      for (const s of this.statuses.filter((el) => el.status)) {
        text = type[s.status] || s.status;
        idx = list.findIndex((el) => el.text == text);
        if (idx > -1) {
          list[idx].value.push(s.value);
        } else {
          list.push({ value: [s.value], text });
        }
      }
      for (const s of this.statuses) {
        idx = list.findIndex((el) => el.text == s.text);
        if (idx > -1) {
          list[idx].value.push(s.value);
        } else {
          list.push({ value: [s.value], text: s.text });
        }
      }
      return list;
    },
    model() {
      /**заменяем отображение даты конца на др элемент. с подсветкой */
      let model = JSON.parse(JSON.stringify(this.m.list));
      const status = this.statuses.filter((el) => {
        return ["ready", "cancel"].includes(el?.status);
      });
      model.forEach((el) => {
        if (el.name == "date_end") {
          el.type = "dateColor";
          el.status = status.map((s) => {
            return s.value;
          });
        }
        if (el.name == "user_name") {
          // el.textColor = "user_viewedon";
          el.textColor = (values) => {
            return values.user_viewedon ? "" : "red";
          };
        }
        if (el.name == "status") {
          el.search = this.statusList;
        }
        if (el.name == "type") {
          let idx;
          let comment = this.getAccess("task.comments", null, true);
          if (!comment) el.badgeField = null;
          let list = []; //this.m[el.sourceList] || [];
          for (const s of this.m["types"].sort()) {
            if (s.value == "construction-goods") {
            } else {
              idx = list.findIndex((el) => el.text == s.text);
              if (idx > -1) {
                list[idx].value.push(s.value);
              } else {
                list.push({ value: [s.value], text: s.text });
              }
            }
          }
          //  el.search = list;
        }

        if (el?.sourceList) {
          el.options = this.m[el.sourceList];
        }
      });

      model = [
        {
          name: "checked",
          type: "checkbox",
          width: 35,
          isDisableFunc: (r) => {
            return ([0, 1, 100, 101, 200, 201].includes(r.status) && this.canRedirect) || r.user_id == this.$root.profile.id;
            return true;
          },
          hidden: !this.modeRedirect,
        },
        ...model,
      ];
      return model;
    },
    canRedirect() {
      return this.getAccess("task.redirect");
    },
    modelCron() {
      let model = JSON.parse(JSON.stringify(this.mCron.list));
      model.forEach((el) => {
        if (el?.sourceList) {
          el.options = this.mCron[el.sourceList];
        }
      });
      return model;
    },
  },
  watch: {
    showTaskDialog() {
      if (!this.showTaskDialog) {
        this.updateData();
      }
    },
    showCronDialog() {
      if (!this.showCronDialog) {
        this.$refs.tableCron.updateData();
      }
    },
  },
  methods: {
    tryRedirect() {
      let error = "";
      this.redirectList = null;
      let rows = [];
      for (let row of this.$refs.table.data.filter((el) => {
        return el?.checked;
      })) {
        rows.push(row);
      }
      if (!rows.length) {
        error = "Ничего не выбранно!";
        return false;
      }

      if (!error)
        rows = rows.filter((el) => {
          console.log("row", el.id, el.status);
          return [0, 1, 100, 101, 200, 201].includes(el.status);
        });

      let userOld;
      if (!error)
        rows.forEach((el) => {
          if (userOld && el.user_id != userOld) {
            error = "Выбраны задачи разных пользователей!";
            return false;
          }
          userOld = el.user_id;
        });
      if (!error) this.redirectList = rows;

      if (!error) this.showRedirectDialog = true;
      else {
        this.$root.$emit("show-info", {
          text: error,
          type: "error",
        });
      }
    },

    async onRedirect(data) {
      if (!this.redirectList) return;
      let d = [];
      this.redirectList.forEach((el) => {
        d.push(Object.assign({}, data, { id: el.id }));
      });

      this.loading = true;
      let response = await this.$axios.post("/mechti/tasks/redirect", d);
      this.loading = false;
      let status = response.data.status == "ok";
      if (status) {
        this.$root.$emit("show-info", {
          text: "Задачи переназначена",
        });
        this.updateData();
      } else {
        this.$root.$emit("show-info", {
          type: "error",
          text: "Ошибка при переназначении",
        });
      }
    },
    async onContextMenuClick(e) {
      if (e.item.name == "viewThis") {
        await this.$axios.post(this.url + "/set_viewed", { ids: [e.data.id] });
        this.$store.dispatch("state/badges", "task_items");
        this.$refs.table.updateRowData(e.data.id);
      }
      if (e.item.name == "viewAll") {
        this.loading = !this.loading;
        await this.$axios.post(this.url + "/set_viewed");
        this.$store.dispatch("state/badges", "task_items");
        this.updateData();
      }
      if (e.item.name == "viewComments") {
        await this.$axios.post(this.url + "/set_viewed_comments", { ids: [e.data.id] });
        this.$store.dispatch("state/badges", "task_comments");
        this.$refs.table.updateRowData(e.data.id);
      }
    },
    onRowContextMenu(e) {
      this.contextData = e;
      this.$refs.rowContextMenu.showMenu(e.event, e.row);
    },
    onClickMenu(e) {
      if (e.action) e.action();
    },
    onTableRefresh() {
      setTimeout(() => {
        this.$refs.table.init();
      }, 100);
    },
    itemShowClose() {
      this.loading = true;
      this.$refs.table.updateRowData(this.idItemShow);
      this.loading = !true;
      this.idItemShow = 0;
    },

    updateData() {
      if (this.showCronList) {
        this.$refs.tableCron.updateData();
      } else {
        this.$refs.table.updateData();
      }
    },

    onSingleClick(d) {
      if (d.field?.isMenu) {
        if (d.field.name == "type") {
          let menuItems = [
            {
              name: "type",
              title: "Отметить комментарии прочитанными",
              action: async () => {
                await this.$axios.post("mechti/tasks/set_viewed_comments", { ids: [d.row.id] });
                this.$store.dispatch("state/badges", "task_comments");
                this.$refs.table.updateRowData(d.row.id);
              },
            },
          ];
          if (d.row.unviewedscomments_total) this.showPopupMenu(d.field.name, d.row, d.event, { menuItems });
        } else this.showPopupMenu(d.field.name, d.row, d.event);
      }
    },
    onDoubleClick(d) {
      if (this.showCronList) {
        this.showCronDialogFun(d.row.id);
        this.showCronDialog = true;
      } else {
        this.idItemShow = d.row.id;
        return;
        this.$router.push({ name: "tasks_view", params: { id: d.row.id } });
      }
    },

    createNew() {
      this.showTaskDialogFun(0);
    },

    showTaskDialogFun(id) {
      this.idEdit = id;
      this.showTaskDialog = true;
    },
    createNewCron() {
      this.showCronDialogFun(0);
    },
    showCronDialogFun(id) {
      this.idEdit = id;
      this.showCronDialog = true;
    },
    async updateTask(row) {
      await this.$axios.post(this.url, row);
      this.$root.$emit("show-info", {
        text: "Данные записаны",
      });
    },
    onChangeFilter(f) {
      this.$root.setSessionFilter(this.filterName, f);
    },
  },
};
</script>
